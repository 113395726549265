.videos {
  padding: 60px 0px;
}
.videos h1 {
  font-size: 52px;
  font-weight: normal;
  line-height: 62px;
  margin-bottom: 20px;
  text-align: center;
}
.videos .videolist {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.videos .videolist video {
  width: 30%;
  margin: 20px 0px;
  max-height: 182px;
}
