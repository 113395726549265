* {
  margin: 0px;
  padding: 0px;
}
*,
*:focus,
*:hover {
  outline: none;
}
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Asap Regular'), local('Asap-Regular'), url(https://fonts.gstatic.com/s/asap/v13/KFOoCniXp96a-zw.ttf) format('truetype');
}
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Asap Bold'), local('Asap-Bold'), url(https://fonts.gstatic.com/s/asap/v13/KFOnCniXp96aw4A7xUw.ttf) format('truetype');
}
@font-face {
  font-family: HeliaCore;
  src: url(../fonts/HeliaCore-Bold.otf);
  font-weight: 700;
}
@font-face {
  font-family: HeliaCore;
  src: url(../fonts/HeliaCore-Light.otf);
  font-weight: 300;
}
body {
  font-family: HeliaCore, sans-serif;
  color: var(--black);
}
a {
  text-decoration: none;
}
.content {
  width: 1080px;
  margin: 0px auto;
  position: relative;
  z-index: 100;
}
.clear {
  clear: both;
}
.button {
  display: inline-block;
  text-align: center;
  border-radius: 50px;
  margin: 20px auto;
  padding: 10px 50px;
  cursor: pointer;
  transition: all ease 0.2s;
  letter-spacing: 5px;
}
.button.border {
  color: var(--white);
  border: 2px solid #fff;
}
.button.border:hover {
  background: var(--white);
  color: var(--purple);
}
::selection {
  background: var(--blue);
  color: var(--white);
}
::-moz-selection {
  background: var(--blue);
}
input,
button {
  font-family: 'Asap', sans-serif;
}
p,
h1,
h2,
h3,
h4 {
  letter-spacing: 4px;
  text-transform: uppercase;
}
h2,
h3,
h4,
h5 {
  color: var(--blue);
  font-weight: normal;
  text-shadow: -4px 0px 0 var(--pink), 2px 0px 2px rgba(206, 89, 55, 0);
}
:root {
  --pink: #ef7e91;
  --blue: #6897ea;
  --purple: #58167c;
  --white: #fff;
  --black: #969994;
  --bigblack: #3b3b3b;
}
